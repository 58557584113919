import React from "react"

const Tabs = ({ tabEntries, activeTab, onTabChange }) => {
  return (
    <div className="c-tabs__container" id="tabs">
      <div className="a-container a-container--fluidMobile">
        <div className="c-tabs__menu">
          {tabEntries.map(tabEntry => {
            return (
              <div
                key={tabEntry.id}
                className={`c-tabs__item ${tabEntry.id === activeTab &&
                  "active"}`}
                onClick={() => {onTabChange(tabEntry.id);}}
                onKeyDown={null}
                role="link"
              >
                {tabEntry.name}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Tabs

import React from "react"

const Image = ({ image, className, type = "fixed" }) => {
  if (!image) return null
  return (
    <picture style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <source
        srcSet={image.localFile.childImageSharp[type].srcSetWebp}
        type="image/webp"
      />
      <source
        srcSet={image.localFile.childImageSharp[type].srcSet}
        type="image/png"
      />
      <img
        className={className}
        srcSet={image.localFile.childImageSharp[type].srcSet}
        alt={image.title}
      ></img>
    </picture>
  )
}

export default Image
